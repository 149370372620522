import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import infoOne from "../images/nemando-1.png"
import infoTwo from "../images/nemando-2.jpg"
import infoThree from "../images/nemando-3.png"

class Info extends React.Component {
  render() {
    return (
      <div className="infoContainer">
        <Container>
          <Row>
            <Col>
              <div className="infoImage">
                <img
                  src={infoOne}
                  alt="outlines of shapes and confetti in the background "
                />
                <h3>Hugmyndir</h3>
                <h5>Eitthvað að gera hér mjeeh</h5>
              </div>
            </Col>
            <Col>
              <div className="infoImage">
                <img
                  src={infoThree}
                  alt="outlines of shapes and confetti in the background "
                />
                <h3>Sköpun</h3>
                <h5>Eitthvað að gera hér mjeeh</h5>
              </div>
            </Col>
            <Col>
              <div className="infoImage">
                <img
                  src={infoOne}
                  alt="outlines of shapes and confetti in the background "
                />
                <h3>Samvinna</h3>
                <h5>Eitthvað að gera hér mjeeh</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Info
