import React from "react"
import { Container, Col } from 'reactstrap'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
//import { Link } from 'react-scroll'
//import Enlist from '../images/owh-enlist-button.png'
//import Logo from '../images/owh-logo.png'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
    <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "cover-4.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#2d333c`}
          >
            <div className="cover">
              <Container>
                <Col>
                  <div className="headline">
                    <h1>Eftir hverju ertu að bíða!</h1>
                  </div>
                </Col>
              </Container>
            </div>
          </BackgroundImage>
       )
     }
     }
    />
)

const Cover = styled(BackgroundSection)`
  position: relative;
  width: auto;
  min-width: 320px;
  height: 600px;
  max-height: 100vh;
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat,
`

export default Cover
