import React from 'react'
import { Container, Row, Col } from 'reactstrap'

class Signup extends React.Component {
  render() {
    return (
      <div className="signupContainer">
      <Container>
        <Row>
          <Col xs="12" md="6">
            <div className="signup-top">
              <h3>SIGN UP FOR NEMANDO NEWS</h3>
              <h4>GET AN IN-GAME BANNER</h4>
              <h5>Sign up today to receive emails about the latest Nemandi news, videos, offers, and more (as well as other Nemando, products, events, and promotions).
              As a bonus for signing up, you'll receive an in-game player banner. Limit one player banner per account. See here for details.</h5>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="signup-top">

            </div>
              <form action="https://" method="POST">
                <input type="hidden" name="u" value="47160eefe"/>
                <input type="hidden" name="id" value="e00"/>

                <input type="email" autoCapitalize="off" autoCorrect="off" name="MERGE0" id="MERGE0" placeholder="Email Address *"/>
                <div className="field-help"><p>* Please use a Gmail or Google hosted account when signing up for an Android device</p></div>

                <input type="text" name="MERGE1" id="MERGE1" placeholder="First Name *"/>
                <select name="MERGE2" className="device" id="MERGE2" placeholder="Select Device*">
                  <option value="Android">Android</option>
                  <option value="iOS">iOS</option>
                </select>
                <div className="field-help">
                  <p> I consent to Nemando processing my personal data in order to send me Nemando, news & milestone updates by email consistent with Nemando’s <a href="/privacy/">Privacy</a> & <a href="/cookie/">Cookie Policy.</a></p>
                  <p><input type="checkbox" id="gdpr_17439" name="gdpr[17439]" value="Y" /> By clicking "SIGN UP" I acknowledge and understand the Nemando Privacy Policy</p>
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" ></div>
                  <div className="response" id="mce-success-response" ></div>
                </div>
                <div aria-hidden="true"><input className="good" type="text" name="b_47160eefe4438ea6c1f9bee6e_e5b31ed600" tabIndex="-1" defaultValue=""/></div>
                <div className="signup-button">
                    <button className="button" type="submit" name="subscribe" id="mc-embedded-subscribe">

                    </button>
                </div>
              </form>
          </Col>
        </Row>
      </Container>
      </div>
    )
  }
}

export default Signup
