import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cover from "../components/cover"
import Info from "../components/info"
import Signup from "../components/signup"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Cover />
    <Info />
    <Signup />
  </Layout>
)

export default IndexPage
